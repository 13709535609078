// src/pages/PremiumPlans.js
import React, { useState } from 'react';
import { FaStar, FaCheckCircle, FaRocket } from 'react-icons/fa';
import './PremiumPlans.css';

const PremiumPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [formData, setFormData] = useState({
    phone: '09',
    telegramId: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handlePurchaseClick = (planName) => {
    setSelectedPlan(planName);
  };

  const handleCancel = () => {
    setSelectedPlan(null);
    setFormData({ phone: '09', telegramId: '' });
    setError(null);
    setSuccess(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone' && (isNaN(value) || value.length > 11)) return;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePayment = async () => {
    setError(null);
    setSuccess(null);

    // چک کردن خالی نبودن فیلدها
    if (!formData.telegramId) {
      setError('لطفاً آیدی تلگرام را وارد کنید.');
      return;
    }
  };

  return (
    <div className="premium-plans">
      <h1 className="premium-plans__title">خرید اکانت پریمیوم تلگرام</h1>
      <p className="premium-plans__description">بهترین پلن مناسب خود را برای دسترسی به امکانات ویژه تلگرام انتخاب کنید.</p>

      <div className="premium-plans__plans-container">
        {['یک ماهه', 'سه ماهه', 'یک ساله'].map((plan, index) => (
          <div key={plan} className="premium-plans__plan-card">
            {selectedPlan === plan ? (
              <div>
                <h2 className="premium-plans__plan-title">فرم اطلاعات</h2>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="شماره تلفن"
                  required
                  className="premium-plans__input"
                />
                <input
                  type="text"
                  name="telegramId"
                  value={formData.telegramId}
                  onChange={handleInputChange}
                  placeholder="آیدی تلگرام"
                  required
                  className="premium-plans__input"
                />
                <button className="premium-plans__purchase-button" onClick={handlePayment}>پرداخت</button>
                <button className="premium-plans__cancel-button" onClick={handleCancel}>انصراف</button>

                {/* نمایش پیام‌های خطا و موفقیت */}
                {error && <p className="premium-plans__error">{error}</p>}
                {success && <p className="premium-plans__success">{success}</p>}
              </div>
            ) : (
              <div>
                {index === 0 ? <FaRocket className="premium-plans__plan-icon" /> : index === 1 ? <FaCheckCircle className="premium-plans__plan-icon" /> : <FaStar className="premium-plans__plan-icon" />}
                <h2 className="premium-plans__plan-title">{plan}</h2>
                <p className="premium-plans__plan-price">{index === 0 ? '20,000 تومان' : index === 1 ? '55,000 تومان' : '200,000 تومان'}</p>
                <p className="premium-plans__plan-description">دسترسی به امکانات ویژه برای {plan}</p>
                <button className="premium-plans__purchase-button" onClick={() => handlePurchaseClick(plan)}>
                  اکنون به جمع پریمیوم‌ها بپیوندید!
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PremiumPlans;
