

// import axios from 'axios';

// const API_BASE_URL = 'http://localhost:5000/';

// export const fetchRentals = async (page, filters = {}) => {
//     const params = { page };

//     // افزودن فیلترهای موجود
//     if (filters.minPrice) params.min_price = filters.minPrice;
//     if (filters.maxPrice) params.max_price = filters.maxPrice;
//     if (filters.location) params.location = filters.location;

//     // افزودن پارامتر مرتب‌سازی بر اساس قیمت
//     if (filters.sortOrder) params.sort_order = filters.sortOrder;
    

//     const response = await axios.get(`${API_BASE_URL}/api/rentals`, { params });
//     return response.data;
// };

// // در rentalService.js
// export const fetchRentalInteraction = async (rentalId) => {
//     const token = localStorage.getItem('access_token');
//     const response = await fetch(`/api/rentals/${rentalId}/interaction`, {
//         method: 'GET',
//         headers: {
//             'Authorization': `Bearer ${token}`
//         }
//     });
//     const data = await response.json();
//     return data.interaction_type; // 'like'، 'dislike' یا null
// };

// export const getRentalById = async (rentalId) => {
//     try {
//         const response = await axios.get(`${API_BASE_URL}/api/rentals/${rentalId}`);
//         return response.data;
//     } catch (error) {
//         console.error(`Error fetching rental with ID ${rentalId}:`, error);
//         throw error;
//     }
// };




import axios from 'axios';

export const fetchRentals = async (page, filters = {}) => {
    const params = { page };

    // افزودن فیلترهای موجود
    if (filters.minPrice) params.min_price = filters.minPrice;
    if (filters.maxPrice) params.max_price = filters.maxPrice;
    if (filters.location) params.location = filters.location;

    // افزودن پارامتر مرتب‌سازی بر اساس قیمت
    if (filters.sortOrder) params.sort_order = filters.sortOrder;

    const response = await axios.get(`/api/rentals`, { params });
    return response.data;
};


// دریافت اطلاعات ملک خاص
export const getRentalById = async (rentalId) => {
    try {
        const response = await axios.get(`/api/rentals/${rentalId}`);
        return response.data; // اطلاعات ملک را برگشت می‌دهد
    } catch (error) {
        console.error(`Error fetching rental with ID ${rentalId}:`, error);
        throw error;
    }
};

