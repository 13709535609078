import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateStore.css'; // وارد کردن فایل CSS
import {jwtDecode} from 'jwt-decode'; // اصلاح import
import { FaStore, FaEnvelope, FaPhone, FaEdit, FaArrowRight } from 'react-icons/fa';

const CreateStore = () => {
    const [store, setStore] = useState({
        name: '',
        description: '',
        account_number: '',
        national_id: '',
        phone: '',
        email: '',
        address: '',
        zip_code: '',
    });
    const [errors, setErrors] = useState({}); // برای ذخیره خطاهای ورودی
    const [existingStore, setExistingStore] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token');

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                navigate('/login');
            } else {
                axios.get('/api/seller/store', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then(response => {
                    if (response.data) {
                        setExistingStore(response.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching store data:', error);
                });
            }
        } catch (err) {
            console.error('Invalid token:', err);
            navigate('/login');
        }
    }, [navigate]);

    const handleChange = (e) => {
        setStore({
            ...store,
            [e.target.name]: e.target.value,
        });
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    const validateInputs = () => {
        const newErrors = {};
        if (!store.name.trim()) newErrors.name = 'نام فروشگاه الزامی است';
        if (!/^\d{10}$/.test(store.national_id)) newErrors.national_id = 'کد ملی باید 10 رقم باشد';
        if (!/^09\d{9}$/.test(store.phone)) newErrors.phone = 'شماره تلفن باید با 09 شروع شده و 11 رقم باشد';
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.email)) newErrors.email = 'ایمیل نامعتبر است';
        if (!store.account_number.trim()) newErrors.account_number = 'شماره حساب الزامی است';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateInputs();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const token = localStorage.getItem('access_token');
        axios.post('/api/seller/store', store, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then(response => {
            alert('فروشگاه با موفقیت ثبت شد');
            navigate('/seller');
        })
        .catch(error => {
            console.error('Error creating store:', error);
            alert('خطا در ثبت فروشگاه');
        });
    };

    const handleEditStore = () => {
        navigate('/seller/store/edit');
    };

    const handleGoToPanel = () => {
        navigate('/seller');
    };

    return (
        <div className="create-store">
            <h2 className="create-store__title">ثبت فروشگاه جدید</h2>

            {existingStore ? (
                <div className="create-store__existing-store">
                    <h3 className="create-store__existing-store-title">شما فروشگاهی دارید</h3>
                    <p className="create-store__existing-store-info">
                        <FaStore className="create-store__icon" /> <strong>نام فروشگاه: </strong>{existingStore.name}
                    </p>
                    <p className="create-store__existing-store-info">
                        <FaEnvelope className="create-store__icon" /> <strong>ایمیل فروشگاه: </strong>{existingStore.email}
                    </p>
                    <p className="create-store__existing-store-info">
                        <FaPhone className="create-store__icon" /> <strong>شماره تلفن: </strong>{existingStore.phone}
                    </p>
                    <button className="create-store__button" onClick={handleEditStore}><FaEdit /> ویرایش فروشگاه</button>
                    <button className="create-store__button" onClick={handleGoToPanel}><FaArrowRight /> رفتن به پنل فروشنده</button>
                </div>
            ) : (
                <form className="create-store__form" onSubmit={handleSubmit}>
                    <div>
                        <label>نام فروشگاه</label>
                        <input className="create-store__input" type="text" name="name" value={store.name} onChange={handleChange} required />
                        {errors.name && <span className="create-store__error">{errors.name}</span>}
                    </div>
                    <div>
                        <label>توضیحات</label>
                        <textarea className="create-store__input create-store__input--textarea" name="description" value={store.description} onChange={handleChange}></textarea>
                    </div>
                    <div>
                        <label>شماره حساب بانکی</label>
                        <input className="create-store__input" type="text" name="account_number" value={store.account_number} onChange={handleChange} required />
                        {errors.account_number && <span className="create-store__error">{errors.account_number}</span>}
                    </div>
                    <div>
                        <label>کد ملی</label>
                        <input className="create-store__input" type="text" name="national_id" value={store.national_id} onChange={handleChange} />
                        {errors.national_id && <span className="create-store__error">{errors.national_id}</span>}
                    </div>
                    <div>
                        <label>شماره تلفن</label>
                        <input className="create-store__input" type="text" name="phone" value={store.phone} onChange={handleChange} required />
                        {errors.phone && <span className="create-store__error">{errors.phone}</span>}
                    </div>
                    <div>
                        <label>ایمیل</label>
                        <input className="create-store__input" type="email" name="email" value={store.email} onChange={handleChange} required />
                        {errors.email && <span className="create-store__error">{errors.email}</span>}
                    </div>
                    <div>
                        <label>آدرس</label>
                        <input className="create-store__input" type="text" name="address" value={store.address} onChange={handleChange} />
                    </div>
                    <div>
                        <label>کد پستی</label>
                        <input className="create-store__input" type="text" name="zip_code" value={store.zip_code} onChange={handleChange} />
                    </div>
                    <button className="create-store__button create-store__button--submit" type="submit">ثبت فروشگاه</button>
                </form>
            )}
        </div>
    );
};

export default CreateStore;
