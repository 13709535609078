import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRentalById } from '../services/rentalService';
import { useRentals } from '../context/RentalsContext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './RentalDetails.css';

const RentalDetails = () => {
    const { rentalId } = useParams();
    const { rentals, setRentals } = useRentals();
    const [loading, setLoading] = useState(true);
    const rental = rentals.find(r => r.id === parseInt(rentalId));

    useEffect(() => {
        const fetchRental = async () => {
            if (!rental) {
                try {
                    setLoading(true);
                    const rentalDetail = await getRentalById(rentalId);
                    setRentals(prev => {
                        if (!prev.some(r => r.id === rentalDetail.id)) {
                            return [...prev, rentalDetail];
                        }
                        return prev;
                    });
                } catch (error) {
                    console.error("Error fetching rental details:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchRental();
    }, [rentalId, rental, setRentals]);

    const formatPrice = (price) => {
        return new Intl.NumberFormat('fa-IR').format(price) + ' تومان';
    };

    if (loading) return <p>در حال بارگذاری...</p>;

    if (!rental) return <p>مشکلی در بارگذاری اطلاعات پیش آمده است</p>;

    return (
        <div className='RentalsDetalis__body'>
            <div className="RentalDetails__container">
                {/* <h1 className="RentalDetails__title">{rental.name}</h1> */}
                <div key={rental.id} className="RentalDetails-card">
                <Slider
                    dots={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay={false}
                    swipe={true}
                    dragging={true}
                    className="RentalDetails__slider"
                >
                    {rental.images && rental.images.length > 0 ? (
                        rental.images.map((image, index) => (
                            <div key={index} className="RentalDetails__image-container">
                                <img
                                    src={ image.image_url || '../image_3.jpg'  }
                                    alt={`Property ${index}`}
                                    className="RentalDetails__image"
                                />
                            </div>
                        ))
                    ) : (
                        <div className="RentalDetails__no-image">تصویری موجود نیست</div>
                    )}
                </Slider>

                <div className="RentalDetails__info">
                    <p><span>توضیحات:</span> {rental.description}</p>
                    <p><span>قیمت به ازای هر شب:</span> {formatPrice(rental.price_per_night)}</p>
                    <p><span>ظرفیت:</span> {rental.max_guests} نفر</p>
                    <p><span>موقعیت:</span> {rental.location}</p>
                    <p><span>امکانات:</span> {rental.amenities}</p>
                    <p><span>وضعیت:</span> {rental.is_available ? "در دسترس" : "در حال اجاره"}</p>
                </div>

                <button className="RentalDetails__button">اجاره</button>
                </div>
            </div>
        </div>
    );
};

export default RentalDetails;
