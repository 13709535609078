import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaStore, FaGamepad, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import './Header.css';

const Header = () => {
  const { isAuthenticated,  logout } = useContext(AuthContext);

  return (
      <header className="header">
          <h1>سیمرغ</h1>
          <nav>
              <Link to="/" className="nav-link">
                  <FaHome className="icon" /> خانه
              </Link>
              <Link to="/store" className="nav-link">
                  <FaStore className="icon" /> فروشگاه
              </Link>
              <Link to="/games" className="nav-link">
                  <FaGamepad className="icon" /> بازی‌ها
              </Link>
              
              {/* نمایش دکمه خروج یا ورود */}
              <div className="auth-buttons">
                  {isAuthenticated ? (
                      <div className="auth-button" onClick={logout}>
                          <FaSignOutAlt className="icon"/> خروج {/* افزودن آیکون خروج */}
                      </div>
                  ) : (
                      <Link to="/login" className="auth-button">
                          <FaSignInAlt className="icon"/> ورود {/* افزودن آیکون ورود */}
                      </Link>
                  )}
              </div>
          </nav>
      </header>
  );
};

export default Header;
