import React from 'react';
import './NotFound.css'; // اطمینان حاصل کنید که این CSS را اضافه کرده‌اید

const NotFound = () => {
    return (
        <div className="not-found">
            <h1>404</h1>
            <h2>صفحه پیدا نشد</h2>
            <p>متاسفانه، صفحه‌ای که به دنبال آن هستید وجود ندارد.</p>
            <a href="/" className="go-home">به صفحه اصلی برگردید</a>
        </div>
    );
};

export default NotFound;
