// src/App.js
import React from 'react';
import { Routes, Route  } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Store from './pages/Store';
import Games from './pages/Games';
import Cart from './pages/Cart';
import ProductDetails from './pages/ProductDetails';
import NotFound from './pages/NotFound';
import './App.css';

import { ProductProvider } from './context/ProductContext'; // اضافه کردن ProductProvider
import { RentalsProvider } from './context/RentalsContext';
import Login from './components/Login';
import Register from './components/Register';
import { HelmetProvider } from 'react-helmet-async';
import PremiumPlans from './pages/PremiumPlans';
import RentalsList from './pages/RentalsList';
import RentalDetails from './pages/RentalDetails';
import AboutUs from './pages/About'; // اضافه کردن صفحه درباره ما
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import { CartProvider } from './context/CartContext'; // وارد کردن CartProvider
import CreateStore from './pages/CreateStore'; 
import SellerDashboard from './pages/SellerDashboard';

const App = () => {
    return (
            <HelmetProvider>
                <AuthProvider>
                    <ProductProvider> 
                        <RentalsProvider>
                        <CartProvider>
                                <div className="app-container">
                                    <Header />
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/product/:productId" element={<ProductDetails />} />
                                        <Route path="/store" element={<Store />} />
                                        <Route path="/games" element={<Games />} />
                                        <Route path="/cart" element={<Cart />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/register" element={<Register />} />
                                        <Route path="/premium-plans" element={<PremiumPlans />} /> 
                                        <Route path="/rentals" element={<RentalsList />} />
                                        <Route path="/rental/:rentalId" element={<RentalDetails />} />
                                        <Route path="/about" element={<AboutUs />} /> {/* مسیر جدید */}
                                        <Route path="/admin/login" element={<AdminLogin />} />
                                        <Route path="/private/admin" element={<AdminDashboard />}/>
                                        <Route path="/seller/create-store" element={<CreateStore />} /> {/* مسیر ثبت فروشگاه */}
                                        <Route path="/seller" element={<SellerDashboard />} />

                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                    <Footer />
                                </div>
                                </CartProvider>
                        </RentalsProvider>
                    </ProductProvider>
                </AuthProvider>
            </HelmetProvider>

    );
};

export default App;
