// src/components/Register.js
import React, { useState, useContext } from 'react';
import { register as authRegister } from '../services/AuthService';
import { AuthContext } from '../context/AuthContext'; // اضافه کردن کانتکست
import { useNavigate } from 'react-router-dom'; // اضافه کردن useNavigate
import './Register.css'; // اضافه کردن CSS

const Register = () => {
    const { login } = useContext(AuthContext); // استفاده از کانتکست
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // استفاده از useNavigate

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        // اعتبارسنجی ورودی‌ها
        if (!/^[a-zA-Z0-9]+$/.test(username)) {
            setError('نام کاربری باید فقط شامل حروف انگلیسی باشد.');
            return;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setError('لطفاً یک آدرس ایمیل معتبر وارد کنید.');
            return;
        }

        if (password.length < 8) {
            setError('رمز عبور باید حداقل 8 کاراکتر باشد.');
            return;
        }

        try {
            const data = await authRegister(username, email, password);
            setMessage('ثبت نام با موفقیت انجام شد.');
            login(data); // ذخیره اطلاعات کاربر
            navigate(window.history.back()); // هدایت به صفحه اصلی
            setUsername('');
            setEmail('');
            setPassword('');
        } catch (err) {
            if (err.response && err.response.data.msg) {
                setError(err.response.data.msg);
            } else {
                setError('ثبت نام ناموفق');
            }
        }
    };

    return (
        <div className="register-container">
            <h2>ثبت نام</h2>
            <form onSubmit={handleSubmit} className="register-form">
                <input
                    type="text"
                    placeholder="نام کاربری"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="ایمیل"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="رمز عبور"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="register-button">ثبت نام</button>
            </form>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
            <div className="redirect">
            <span className="register-link" onClick={() => navigate('/login')}>حساب کاربری دارید؟ </span>
            </div>
        </div>
    );
};

export default Register;