// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';
import { FaStore, FaGamepad, FaHome } from 'react-icons/fa';

const AboutUs = () => {
    return (
        <div className="about-us">
            <section className="intro">
                <img src="../assets/images/Mohammad.jpg" alt="محمد اکبری" className="profile-image" />
                <h1>درباره ما</h1>
                <p>
                    سلام! من محمد اکبری هستم، بنیان‌گذار و مدیر سایت سیمرغ. با چندین سال تجربه در زمینه‌های مختلف دیجیتال، هدف من ارائه خدماتی کامل و منحصربه‌فرد به شماست. به دنبال ساخت یک بستر جامع برای خرید آنلاین، اجاره املاک، بازی‌های دیجیتال، و امکاناتی هستم که تجربه کاربری شما را بهبود ببخشد.
                </p>
            </section>

            <section className="services">
                <h2>خدمات ما</h2>
                <div className="service-cards">
                    <div className="service-card">
                        <FaStore className="service-icon" />
                        <h3>فروشگاه آنلاین</h3>
                        <p>محصولات متنوع و باکیفیت در دسته‌بندی‌های مختلف.</p>
                    </div>
                    <div className="service-card">
                        <FaHome className="service-icon" />
                        <h3>اجاره باغ و ویلا</h3>
                        <p>باغ‌ها و ویلاهای شیک برای اجاره با قیمت‌های مناسب.</p>
                    </div>
                    <div className="service-card">
                        <FaGamepad className="service-icon" />
                        <h3>بازی‌های دیجیتال</h3>
                        <p>بازی‌های آنلاین جذاب برای سرگرمی و اوقات فراغت شما.</p>
                    </div>
                </div>
            </section>

            <section className="founder">
                <h2>آشنایی با من</h2>
                <p>
                    به عنوان یک متخصص دیجیتال مارکتینگ و توسعه دهنده، همیشه به دنبال فرصت‌های نوین و خدمات منحصر به فرد برای کاربران بوده‌ام. از زمان آغاز فعالیت، تمرکز من بر کیفیت، رضایت مشتری، و توسعه مداوم بوده است تا سیمرغ به یکی از بهترین بسترها در این حوزه تبدیل شود.
                </p>
            </section>
        </div>
    );
};

export default AboutUs;
