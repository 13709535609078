// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // تغییر نام import به jwtDecode
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    
    const login = (userData) => {
        setUser(userData);
        setIsAuthenticated(true);
    };

    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('access_token');
    };

    const showSnackbar = (message, severity = 'info') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('access_token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token); // استفاده از jwtDecode
                    const currentTime = Date.now() / 1000;


                    // بررسی انقضای توکن
                    if (decodedToken.exp < currentTime) {
                        logout(); // در صورت انقضای توکن خروج از سیستم
                        showSnackbar(`شما با نام کاربری "${decodedToken.username}" از سیستم خارج شدید. لطفاً دوباره وارد شوید.`, 'warning');
                        
                    } else {
                        // اگر توکن معتبر باشد، وضعیت کاربر را تنظیم می‌کنیم
                        setUser({ id: decodedToken.id, username: decodedToken.username });
                        setIsAuthenticated(true);
                    }
                } catch (error) {
                    console.error("Error decoding token:", error);
                    logout(); // در صورت خطا در دیکد توکن خروج از سیستم
                    
                }
            }
        };

        checkToken(); // فراخوانی چک توکن هنگام بارگذاری
    }, []); // این اثر تنها یک بار در هنگام بارگذاری کامپوننت اجرا می‌شود

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {children}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </AuthContext.Provider>
    );
};

// تابع useAuth برای استفاده در سایر کامپوننت‌ها
export const useAuth = () => {
    return useContext(AuthContext);
};
