import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

// ایجاد کانتکست سبد خرید
export const CartContext = createContext();

// کامپوننت پروایدر برای کانتکست سبد خرید
export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState({}); // سبد خرید به صورت شیء است که کلید‌ها شناسه محصول و مقادیر شامل اطلاعات محصول و تعداد هستند
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);



    const addToCart = async (product) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید.');
            navigate('/login')
            return;
        }
        try {
            // ارسال درخواست به سرور برای اضافه کردن محصول
            await fetch('/cart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                body: JSON.stringify({ product_id: product.id, quantity: 1 }),
            });

            // به‌روزرسانی وضعیت سبد خرید در سمت کلاینت
            setCart((prevCart) => {
                const existingItem = prevCart[product.id];
                return {
                    ...prevCart,
                    [product.id]: {
                        productDetails: product,
                        quantity: existingItem ? existingItem.quantity + 1 : 1,
                    },
                };
            });
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    const deleteItemById = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید.');
            navigate('/login')
            return;
        }
        try {
            // ارسال درخواست به سرور برای حذف محصول
            await fetch(`/cart/${productId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            // به‌روزرسانی وضعیت سبد خرید در سمت کلاینت
            setCart((prevCart) => {
                const updatedCart = { ...prevCart };
                delete updatedCart[productId];
                return updatedCart;
            });
        } catch (error) {
            console.error('Error deleting product from cart:', error);
        }
    };

    const removeFromCart = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید.');
            navigate('/login')
            return;
        }
        try {
            // ارسال درخواست به سرور برای کاهش تعداد محصول
            await fetch('/cart', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                body: JSON.stringify({ product_id: productId, quantity: -1 }),
            });

            // به‌روزرسانی وضعیت سبد خرید در سمت کلاینت
            setCart((prevCart) => {
                const updatedCart = { ...prevCart };
                if (updatedCart[productId].quantity > 1) {
                    updatedCart[productId].quantity -= 1;
                } else {
                    delete updatedCart[productId];
                }
                return updatedCart;
            });
        } catch (error) {
            console.error('Error removing product from cart:', error);
        }
    };

    const fetchCartFromServer = useCallback(async () => {
        if (!isAuthenticated) {
            return;
        }
        
        try {
            const response = await fetch('/cart', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch cart');
            }
            
            const serverCart = await response.json();
            
            // تبدیل داده‌ها به ساختار مناسب
            const formattedCart = {};
            serverCart.items.forEach(item => {
                formattedCart[item.product_id] = {
                    productDetails: {
                        name: item.name,
                        id: item.product_id,
                        price: item.price
                    },
                    quantity: item.quantity
                };
            });
            
            // به روز رسانی وضعیت سبد خرید
            setCart(formattedCart);
            
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
    }, [isAuthenticated]); // وابستگی به isAuthenticated
    

    // فراخوانی fetchCartFromServer زمانی که کاربر وارد سیستم شده باشد
    useEffect(() => {
        if (isAuthenticated) {
            fetchCartFromServer();
        }
    }, [isAuthenticated, fetchCartFromServer]);  // Dependency on fetchCartFromServer

    const getCartItemCount = () => {
        return Object.values(cart).reduce((total, item) => total + item.quantity, 0); // تعداد کل محصولات در سبد خرید
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, getCartItemCount, deleteItemById, setCart }}>
            {children}
        </CartContext.Provider>
    );
};

// هک برای استفاده از کانتکست در کامپوننت‌ها
export const useCart = () => {
    return useContext(CartContext);
};
