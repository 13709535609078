import React, { useState } from 'react';
import './Games.css';

const Games = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <section className="games">
      <button className="btn main-btn" onClick={toggleDropdown}>
        انتخاب بازی‌ها <i className="fas fa-caret-down"></i>
      </button>
      {showDropdown && (
        <div className="dropdown-content">
          <a href="/quiz" className="dropdown-item">
            <i className="fas fa-question-circle"></i> بازی پرسش و پاسخ
          </a>
          <a href="/backgammon" className="dropdown-item">
            <i className="fas fa-chess-board"></i> تخته نرد
          </a>
          <a href="/coming-soon" className="dropdown-item">
            <i className="fas fa-gamepad"></i> بازی‌های دیگر (به زودی)
          </a>
        </div>
      )}
    </section>
  );
};

export default Games;
