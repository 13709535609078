import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SellerDashboard.css';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // اصلاح import
const SellerDashboard = () => {
    const [products, setProducts] = useState([]);
    const [searchId, setSearchId] = useState('');
    const [searchedProduct, setSearchedProduct] = useState(null);
    const [editProduct, setEditProduct] = useState(null);
    const [newProduct, setNewProduct] = useState({
        name: '',
        price: '',
        quantity: '',
        category: '',
        tags: ''
    });
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token'); // دریافت توکن از localStorage
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
            console.error('توکن معتبر نیست!');
            navigate('/login'); // اگر توکن معتبر نیست، کاربر را به صفحه ورود هدایت کنید
            return;
        }
    

        const fetchStoreData = async () => {
            try {
                const response = await axios.get('/api/seller/store', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (!response.data) navigate('/seller/create-store');
            } catch (error) {
                navigate('/seller/create-store');
            }
        };

        fetchStoreData();
    }, [navigate]);

    const fetchProducts = () => {
        setIsLoading(true);
        const token = localStorage.getItem('access_token');
        axios.get('/api/seller/products', {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => {
                setProducts(response.data.products);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            });
    };

    const fetchOrders = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/seller/orders', {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => {
                setOrders(response.data.orders);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
            });
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const handleDeleteProduct = (productId) => {
        const token = localStorage.getItem('access_token');
        axios.delete(`/api/seller/products/${productId}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(() => {
                setMessage('محصول با موفقیت حذف شد');
                setProducts(products.filter(product => product.id !== productId));
                setSearchedProduct(null);
            })
            .catch(error => {
                setMessage('خطا در حذف محصول');
                console.error('Error deleting product:', error);
            });
    };

    const handleSearch = () => {
        const token = localStorage.getItem('access_token');
        axios.get(`/api/seller/product/${searchId}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => {
                setSearchedProduct(response.data.product);
                setEditProduct(response.data.product);
                setMessage('');
            })
            .catch(error => {
                setMessage('محصولی با این شناسه یافت نشد');
                console.error('Error searching product:', error);
            });
    };

    const handleUpdateProduct = () => {
        const token = localStorage.getItem('access_token');
        axios.put(`/api/seller/product/${editProduct.id}`, editProduct, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(() => {
                setMessage('محصول با موفقیت ویرایش شد');
                setProducts(products.map(product => product.id === editProduct.id ? editProduct : product));
                setEditProduct(null);
            })
            .catch(error => {
                setMessage('خطا در ویرایش محصول');
                console.error('Error updating product:', error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditProduct({ ...editProduct, [name]: value });
    };


    const handleAddNewProduct = () => {
        const token = localStorage.getItem('access_token');
    
        // ایجاد فرم داده برای ارسال به سرور
        const formData = new FormData();
        formData.append('name', newProduct.name);
        formData.append('price', newProduct.price);
        formData.append('quantity', newProduct.quantity);
        formData.append('category', newProduct.category);
        formData.append('tags', newProduct.tags);
        formData.append('discount', newProduct.discount);
        if (newProduct.image) {
            formData.append('image', newProduct.image);  // اضافه کردن تصویر
        }
    
        axios.post('/api/seller/product', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                setMessage('محصول جدید با موفقیت اضافه شد');
                setProducts([...products, response.data.product]);
                setNewProduct({
                    name: '',
                    price: '',
                    quantity: '',
                    category: '',
                    tags: '',
                    discount: '',
                    image: null,  // reset image after submission
                });
                setShowAddForm(false);
            })
            .catch(error => {
                setMessage('خطا در اضافه کردن محصول');
                console.error('Error adding new product:', error);
            });
    };
    
    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    };
    
    // افزودن فیلد برای آپلود تصویر
    const handleImageChange = (e) => {
        setNewProduct({ ...newProduct, image: e.target.files[0] });
    };

    if (isLoading) return <p>در حال بارگذاری...</p>;

    return (
        <div className="seller-dashboard">
            <h2 className="seller-dashboard__title">پنل فروشندگان</h2>

            {message && <div className="seller-dashboard__message">{message}</div>}

            <div className="seller-dashboard__actions">
                <button className="seller-dashboard__button" onClick={() => setShowAddForm(true)}>اضافه کردن محصول</button>
                <button className="seller-dashboard__button" onClick={() => setShowEditForm(true)}>ویرایش یا حذف محصول</button>
                <button className="seller-dashboard__button" onClick={() => {
                    if (!showAllProducts) fetchProducts();
                    setShowAllProducts(!showAllProducts);
                }}>
                    {showAllProducts ? 'پنهان کردن محصولات' : 'نمایش تمام محصولات'}
                </button>
            </div>

            {showAddForm && (
                <div className="seller-dashboard__form">
                    <h3 className="seller-dashboard__form-title">اضافه کردن محصول جدید</h3>
                    <form onSubmit={(e) => { e.preventDefault(); handleAddNewProduct(); }}>
                        <input
                            className="seller-dashboard__input"
                            type="text"
                            name="name"
                            placeholder="نام محصول"
                            value={newProduct.name}
                            onChange={handleNewProductChange}
                            required
                        />
                        <input
                            className="seller-dashboard__input"
                            type="number"
                            name="price"
                            placeholder="قیمت"
                            value={newProduct.price}
                            onChange={handleNewProductChange}
                            required
                        />
                        <input
                            className="seller-dashboard__input"
                            type="number"
                            name="quantity"
                            placeholder="موجودی"
                            value={newProduct.quantity}
                            onChange={handleNewProductChange}
                            required
                        />
                        <input
                            className="seller-dashboard__input"
                            type="text"
                            name="category"
                            placeholder="دسته‌بندی"
                            value={newProduct.category}
                            onChange={handleNewProductChange}
                        />
                        <input
                            className="seller-dashboard__input"
                            type="text"
                            name="tags"
                            placeholder="برچسب‌ها"
                            value={newProduct.tags}
                            onChange={handleNewProductChange}
                        />
                        <input
                            className="seller-dashboard__input"
                            type="number"
                            name="discount"
                            placeholder="مبلغ تخفیف"
                            value={newProduct.discount}
                            onChange={handleNewProductChange}
                        />
                        <input
                            className="seller-dashboard__input"
                            type="file"
                            name="image"
                            onChange={handleImageChange}
                        />
                        <button className="seller-dashboard__button seller-dashboard__button--submit" type="submit">اضافه کردن محصول</button>
                        <button className="seller-dashboard__button" type="button" onClick={() => setShowAddForm(false)}>لغو</button>
                    </form>
                </div>
            )}

            {showEditForm && (
                <div className="seller-dashboard__form">
                    <h3 className="seller-dashboard__form-title">جستجو و ویرایش محصول</h3>
                    <input
                        className="seller-dashboard__input"
                        type="text"
                        placeholder="شناسه محصول"
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value)}
                    />
                    <button className="seller-dashboard__button" onClick={handleSearch}>جستجو</button>

                    {searchedProduct && (
                        <form onSubmit={(e) => { e.preventDefault(); handleUpdateProduct(); }}>
                            <input
                                className="seller-dashboard__input"
                                type="text"
                                name="name"
                                value={editProduct?.name || ''}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                className="seller-dashboard__input"
                                type="number"
                                name="price"
                                value={editProduct?.price || ''}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                className="seller-dashboard__input"
                                type="number"
                                name="quantity"
                                value={editProduct?.quantity || ''}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                className="seller-dashboard__input"
                                type="text"
                                name="category"
                                value={editProduct?.category || ''}
                                onChange={handleInputChange}
                            />
                            <input
                                className="seller-dashboard__input"
                                type="text"
                                name="tags"
                                value={editProduct?.tags || ''}
                                onChange={handleInputChange}
                            />
                            <button className="seller-dashboard__button seller-dashboard__button--submit" type="submit">ذخیره تغییرات</button>
                            <button className="seller-dashboard__button" type="button" onClick={() => setEditProduct(null)}>لغو</button>
                        </form>
                    )}

                    {searchedProduct && (
                        <button
                            className="seller-dashboard__button seller-dashboard__button--delete"
                            onClick={() => handleDeleteProduct(searchedProduct.id)}
                        >
                            حذف محصول
                        </button>
                    )}

                    <button className="seller-dashboard__button" onClick={() => setShowEditForm(false)}>بستن</button>
                </div>
            )}

            {showAllProducts && (
                <div className="seller-dashboard__products">
                    <h3 className="seller-dashboard__section-title">محصولات</h3>
                    {products.map(product => (
                        <div key={product.id} className="seller-dashboard__product">
                            <p><strong>نام:</strong> {product.name}</p>
                            <p><strong>قیمت:</strong> {product.price}</p>
                            <p><strong>موجودی:</strong> {product.quantity}</p>
                            <p><strong>دسته‌بندی:</strong> {product.category}</p>
                            <p><strong>برچسب‌ها:</strong> {product.tags}</p>
                        </div>
                    ))}
                </div>
            )}

            <div className="seller-dashboard__orders">
                <h3 className="seller-dashboard__section-title">سفارش‌ها</h3>
                {orders.length > 0 ? (
                    <ul>
                        {orders.map(order => (
                            <li key={order.id}>
                                سفارش شماره {order.id} - وضعیت: {order.status}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>هیچ سفارشی موجود نیست.</p>
                )}
            </div>
        </div>
    );
};

export default SellerDashboard;
