import React, { useState, useEffect } from 'react';
import './FilterBox.css';

const cities = ['تهران', 'اصفهان', 'شیراز', 'مشهد', 'تبریز', 'رشت'];

const FilterBox = ({ onApplyFilters, selectedFilters }) => {
    const [filters, setFilters] = useState({ sortOrder: '', location: '', minPrice: '', maxPrice: '' });
    const [citySearch, setCitySearch] = useState('');
    const [showCityDropdown, setShowCityDropdown] = useState(false);
    const [showPriceDropdown, setShowPriceDropdown] = useState(false);
    const [customPriceRange, setCustomPriceRange] = useState({ min: '', max: '' });
    const [showCustomPriceForm, setShowCustomPriceForm] = useState(false);
    

    useEffect(() => {
        setFilters(selectedFilters);
    }, [selectedFilters]);

    const handleFilterChange = (filterType, value) => {
        const updatedFilters = { ...filters, [filterType]: value };

        // پاک‌کردن فیلترهای سفارشی در صورت انتخاب مرتب‌سازی عادی
        if (filterType === 'sortOrder' && (value === 'asc' || value === 'desc')) {
            updatedFilters.minPrice = '';
            updatedFilters.maxPrice = '';
            setCustomPriceRange({ min: '', max: '' });
        }

        setFilters(updatedFilters);
        if (filterType !== 'sortOrder' || value !== 'custom') {
            onApplyFilters(updatedFilters);
        }
    };

    const handleClearFilter = (filterType) => {
        const updatedFilters = { ...filters, [filterType]: '' };

        if (filterType === 'sortOrder' && filters.sortOrder === 'custom') {
            updatedFilters.minPrice = '';
            updatedFilters.maxPrice = '';
            setCustomPriceRange({ min: '', max: '' });
        }

        setFilters(updatedFilters);
        onApplyFilters(updatedFilters);
    };

    const handleCustomPriceSubmit = () => {
        const updatedFilters = {
            ...filters,
            minPrice: customPriceRange.min,
            maxPrice: customPriceRange.max,
            sortOrder: 'custom'
        };
        setFilters(updatedFilters);
        onApplyFilters(updatedFilters);
        setShowPriceDropdown(false); // بستن منوی کشویی پس از اعمال
            // پاک‌کردن ورودی‌ها بعد از تأیید
        setCustomPriceRange({ min: '', max: '' });
        setShowCustomPriceForm(false); // بستن فرم قیمت سفارشی
        
    };

    const filteredCities = cities.filter((city) =>
        city.toLowerCase().includes(citySearch.toLowerCase())
    );

    return (
        <div className="filter-box">
            {/* فیلتر شهر */}
            <div className="filter-box__section">
                <div className="filter-box__label" onClick={() => setShowCityDropdown(!showCityDropdown)}>
                    فیلتر شهر
                </div>
                {showCityDropdown && (
                    <div className="filter-box__dropdown-content">
                        <input
                            type="text"
                            placeholder="جستجوی شهر..."
                            value={citySearch}
                            onChange={(e) => setCitySearch(e.target.value)}
                            style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
                        />
                        {filteredCities.map((city) => (
                            <div
                                key={city}
                                onClick={() => {
                                    handleFilterChange('location', city);
                                    setShowCityDropdown(false); // بستن منوی کشویی پس از انتخاب
                                }}
                            >
                                {city}
                            </div>
                        ))}
                    </div>
                )}
                {filters.location && (
                    <span className="filter-box__highlight" onClick={() => handleClearFilter('location')}>
                        {filters.location} ×
                    </span>
                )}
            </div>

            {/* فیلتر قیمت */}
            <div className="filter-box__section">
                <div className="filter-box__label" onClick={() => setShowPriceDropdown(!showPriceDropdown)}>
                    فیلتر قیمت
                </div>
                {showPriceDropdown && (
                    <div className="filter-box__dropdown-content">
                        <div onClick={() => {
                            handleFilterChange('sortOrder', 'asc');
                            setShowPriceDropdown(false); // بستن منوی کشویی
                        }}>کم به زیاد</div>
                        <div onClick={() => {
                            handleFilterChange('sortOrder', 'desc');
                            setShowPriceDropdown(false); // بستن منوی کشویی
                        }}>زیاد به کم</div>
                        <div onClick={() => {
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                sortOrder: 'custom'
                            }));
                            setShowPriceDropdown(false); // بستن منوی کشویی پس از انتخاب قیمت 
                            setShowCustomPriceForm(true); // باز کردن فرم قیمت سفارشی
                        }}>سفارشی</div>
                    </div>
                )}
                {filters.sortOrder && (
                    <span className="filter-box__highlight" onClick={() => handleClearFilter('sortOrder')}>
                        {filters.sortOrder === 'asc' ? 'کم به زیاد' : filters.sortOrder === 'desc' ? 'زیاد به کم' : 'قیمت سفارشی'} ×
                    </span>
                )}
                {filters.sortOrder === 'custom' && showCustomPriceForm &&(
                    <div className="filter-box__custom-price-range">
                        <input
                            type="number"
                            placeholder="حداقل قیمت"
                            value={customPriceRange.min}
                            onChange={(e) => setCustomPriceRange({ ...customPriceRange, min: e.target.value })}
                        />
                        <input
                            type="number"
                            placeholder="حداکثر قیمت"
                            value={customPriceRange.max}
                            onChange={(e) => setCustomPriceRange({ ...customPriceRange, max: e.target.value })}
                        />
                        <button className='button-confirm-price' onClick={handleCustomPriceSubmit}>
                            تایید
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
    
};

export default FilterBox;
