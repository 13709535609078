import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { fetchRentals } from '../services/rentalService';
import FilterBox from './FilterBox';
import { useRentals } from '../context/RentalsContext';
import { AuthContext } from '../context/AuthContext';
import { FaArrowRight } from 'react-icons/fa';
import './RentalsList.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // Importing the slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RentalsList = () => {
    const { rentals, setRentals, filters, setFilters ,loading,setLoading,currentPage,setCurrentPage,totalPages,setTotalPages,error,setError} = useRentals();
    const { isAuthenticated } = useContext(AuthContext);

    const prevFiltersRef = useRef(filters);
    const prevPageRef = useRef(currentPage);
    const [hasError, setHasError] = useState(false); // حالت جدید برای مدیریت خطا

    const formatPrice = (price) => {
        return new Intl.NumberFormat('fa-IR').format(price) + ' تومان';
    };
    
    const loadRentals = useCallback(async () => {
        if (loading || hasError) return;
    
        setLoading(true);
        try {
            const data = await fetchRentals(currentPage, filters);
            setRentals(prevRentals => (currentPage === 1 ? data.rentals : [...prevRentals, ...data.rentals]));
            setTotalPages(Math.ceil(data.total / 9));
            setHasError(false);  // در صورت موفقیت، خطا را بازنشانی کنید
            setError(false)
        } catch (err) {
            setError('Error loading rentals',err);
            setHasError(true);  // فعال‌سازی حالت خطا
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [currentPage, filters, loading, setRentals, setError, setLoading, setTotalPages, hasError]);

    useEffect(() => {
        const filtersChanged = JSON.stringify(filters) !== JSON.stringify(prevFiltersRef.current);
        const pageChanged = currentPage !== prevPageRef.current;
    
        if ((filtersChanged || pageChanged) && !hasError) {
            loadRentals();
            prevFiltersRef.current = filters;
            prevPageRef.current = currentPage;
        }
        if (rentals.length === 0 && totalPages>0 && Object.values(filters).every(value => !value) && !hasError) { 
            // فقط در صورتی که اجاره‌ها خالی و فیلترها نیز خالی هستند، بارگذاری کنید
            loadRentals(1);
        }
    }, [filters, currentPage, loadRentals, rentals, hasError]);
    
    const handleRetry = () => {
        setHasError(false); // ریست کردن خطا
        loadRentals();      // دوباره درخواست را ارسال کنید
    };

    const handleApplyFilters = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1);
        setRentals([]);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages && !loading) {
            setCurrentPage(prev => prev + 1);
        }
    };

    // تابع برای لایک کردن ملک
    const handleLike = async (rentalId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!');
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`api/rentals/${rentalId}/like`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.ok) {
            setRentals((prevRentals) =>
                prevRentals.map((rental) =>
                    rental.id === rentalId ? {
                        ...rental,
                        likes: data.likes,
                        dislikes: data.dislikes,
                        likedByUser: !rental.likedByUser,
                        dislikedByUser: false
                    } : rental
                )
            );
        } else {
            console.error('Error liking rental:', data);
        }
    };

    // تابع برای دیس‌لایک کردن ملک
    const handleDislike = async (rentalId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!');
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/rentals/${rentalId}/dislike`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.ok) {
            setRentals((prevRentals) =>
                prevRentals.map((rental) =>
                    rental.id === rentalId ? {
                        ...rental,
                        dislikes: data.dislikes,
                        likes: data.likes,
                        dislikedByUser: !rental.dislikedByUser,
                        likedByUser: false
                    } : rental
                )
            );
        } else {
            console.error('Error disliking rental:', data);
        }
    };

    return (
        <div className='RentalsList__body'>
            <FilterBox onApplyFilters={handleApplyFilters} selectedFilters={filters} />
            {error && (
            <div className="error-message">
                <p>خطا در بارگزاری داده ها</p>
                <button onClick={handleRetry}>تلاش دوباره</button>
            </div>
        )}

            <div className="rentals-container">
                {rentals.map((rental) => (
                    <div key={rental.id} className="rental-card">
                        <Slider
                            dots={true}
                            infinite={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            autoplay={false}
                            swipe={true}
                            dragging={true}
                            lazyLoad="ondemand"
                            className="rental-slider"
                        >
                            {rental.images && rental.images.length > 0 ? (
                                rental.images.map((image, index) => (
                                    <div key={index} className="image-container">
                                        <img
                                            src={image.image_url}
                                            alt={`Property ${rental.id}`}
                                        />
                                    </div>
                                ))
                            ) : (
                                null

                            )}
                        </Slider>

                        <div className="rental-info">
                            <h2>{rental.name}</h2>
                            <p>کد ملک: {rental.id}</p>
                            <p>قیمت به ازای هر شب: {formatPrice(rental.price_per_night)}</p>
                            <p>حداکثر ظرفیت: {rental.max_guests} نفر</p>
                            <div className="availability-status">
                                {rental.is_available ? (
                                    <span className="available">در دسترس</span>
                                ) : (
                                    <span className="not-available">در حال حاضر در دسترس نیست</span>
                                )}
                            </div>
                        </div>

                        <div className="likes-dislikes">
                            <span onClick={() => handleLike(rental.id)} style={{ color: rental.likedByUser ? '#4CAF50' : '#000' }}>
                                <i className="fas fa-thumbs-up"></i> {rental.likes}
                            </span>
                            <span onClick={() => handleDislike(rental.id)} style={{ color: rental.dislikedByUser ? '#f44336' : '#000' }}>
                                <i className="fas fa-thumbs-down"></i> {rental.dislikes}
                            </span>
                        </div>
                        <Link to={`/rental/${rental.id}`}>
                            <button className="rent-button">مشاهده جزئیات</button>
                        </Link>
                    </div>
                ))}
            </div>
            {loading && <div className="loading">در حال بارگذاری...</div>}
            {currentPage===1 && <p>اطلاعات دیگری وجود ندارد.</p>}
            {currentPage < totalPages && rentals.length > 0 && (
                <button className="next-page-button" onClick={handleNextPage}>
                    <FaArrowRight />
                </button>
            )}
        </div>

    );
};

export default RentalsList;
