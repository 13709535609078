import React from 'react';
import './Home.css';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FaStore, FaGamepad, FaHome, FaInfoCircle } from 'react-icons/fa';

const Home = () => {
  const stars = Array.from({ length: 50 }).map((_, index) => ({
    id: index,
    top: Math.random() * 50 + 'vh',
    left: Math.random() * 70 + 'vw',
    delay: Math.random() * 4 + 's',
  }));

  return (
    <>
      <Helmet>
      <title>صفحه اصلی سیمرغ</title>
      <meta name="description" content="سیمرغ، ارائه‌دهنده خدمات دیجیتال، تلگرام پریمیوم، اجاره ویلا، فروشگاه آنلاین و بازی‌های متنوع. بهترین خدمات آنلاین و سریع." />
      <meta name="keywords" content="سیمرغ, تلگرام پریمیوم, خدمات دیجیتال, خرید آنلاین, اجاره ویلا, فروشگاه آنلاین, بازی‌های آنلاین" />

        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* اینجا هیچ استایلی نمی‌دهیم */}
      <div>
        {/* کانتینر داخلی با استایل‌های خاص */}
        <div className="home-content-container">
          {/* بخش آیکون‌ها */}
          <div className="home-icons">
            <Link to="/store" className="home-icon-box">
              <FaStore className="home-icon" />
              <span className="home-icon-label">فروشگاه</span>
            </Link>
            <Link to="/games" className="home-icon-box">
              <FaGamepad className="home-icon" />
              <span className="home-icon-label">بازی‌ها</span>
            </Link>
            <Link to="/rentals" className="home-icon-box">
              <FaHome className="home-icon" />
              <span className="home-icon-label">اجاره ویلا</span>
            </Link>
            <Link to="/about" className="home-icon-box">
              <FaInfoCircle className="home-icon" />
              <span className="home-icon-label">درباره ما</span>
            </Link>
          </div>

          {/* بخش تلگرام پریمیوم */}
          <div className="home-premium-section">
            <div className="home-stars-container">
              {stars.map((star) => (
                <div
                  key={star.id}
                  className="home-star"
                  style={{
                    top: star.top,
                    left: star.left,
                    animationDelay: star.delay,
                  }}
                />
              ))}
            </div>

            <div className="home-premium-content">
              <Link to="/premium-plans" className="home-premium-star-container">
                <span className="home-big-star">★</span>
                <span className="home-small-star home-small-star--1">★</span>
                <span className="home-small-star home-small-star--2">★</span>
                <span className="home-small-star home-small-star--3">★</span>
                <span className="home-small-star home-small-star--4">★</span>
                <span className="home-small-star home-small-star--5">★</span>
                <span className="home-small-star home-small-star--5">★</span>
              </Link>
              <Link to="/premium-plans" className="home-premium-text">
                خرید تلگرام پریمیوم
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
