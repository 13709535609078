import React, { useState, useContext } from 'react';
import './Comment.css'; // فایل CSS
import { AuthContext } from '../context/AuthContext';
import { FaPaperPlane } from 'react-icons/fa'; // آیکون پرواز کاغذی از react-icons

const Comment = ({ productId, onCommentAdded }) => {
    const { isAuthenticated, user } = useContext(AuthContext);
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!isAuthenticated) {
            setError('لطفاً ابتدا وارد حساب کاربری خود شوید.');
            return;
        }

        if (!comment) {
            setError('لطفاً کامنت خود را وارد کنید.');
            return;
        }

        try {
            const response = await fetch(`/api/products/${productId}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
                body: JSON.stringify({ user_name: user.username, comment }),
            });
            if (response.ok) {
                // اضافه کردن کامنت به لیست با استفاده از onCommentAdded
                onCommentAdded({ user_name: user.username, comment }); 
                setComment(''); // پاک کردن فرم
                setError('');
            } else {
                setError('خطا در ارسال کامنت.');
            }
        } catch (error) {
            setError('خطا در ارسال درخواست.');
            console.error('Error posting comment:', error);
        }
    };

    return (
        <div className="comment-container">
            <h2 className="comment-title">
                {isAuthenticated ? 'اضافه کردن کامنت' : 'برای کامنت گذاشتن وارد شوید'}
            </h2>
            {error && <p className="comment-error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="comment-input"
                    placeholder="نام شما"
                    value={isAuthenticated ? user.username : ''}
                    readOnly
                    disabled={!isAuthenticated}
                />
                <textarea
                    className="comment-textarea"
                    placeholder="کامنت شما"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={!isAuthenticated}
                    style={{ maxHeight: '150px', overflowY: 'auto' }} // جلوگیری از بیرون زدن متن
                ></textarea>
                <button type="submit" disabled={!isAuthenticated || !comment} className="comment-button">
                    <FaPaperPlane /> {/* آیکون پرواز کاغذی */}
                </button>
            </form>
        </div>
    );
};

export default Comment;
