import React, { useEffect, useContext, useCallback } from 'react';
import './Store.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ProductContext } from '../context/ProductContext';
import { throttle } from 'lodash';
import { CartContext } from '../context/CartContext';

const Store = () => {
    const { products, loading, hasMore, setPage, loadProducts, setProducts, errorLoading, setErororLoading } = useContext(ProductContext);
    const { isAuthenticated } = useContext(AuthContext);
    const { cart, addToCart, removeFromCart, getCartItemCount } = useContext(CartContext);

    const formatPrice = (price) => {
        return new Intl.NumberFormat('fa-IR').format(price) + ' تومان';
    };

    useEffect(() => {
        if (products.length === 0) {
            loadProducts(1);
        }
    }, [products, loadProducts]);

    const loadMoreProducts = useCallback(() => {
        if (hasMore) {
            const nextPage = Math.ceil(products.length / 10) + 1;
            setPage(nextPage);
            loadProducts(nextPage);
        }
    }, [hasMore, products.length, loadProducts, setPage]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            if (scrollTop + windowHeight >= documentHeight - 20 && !loading) {
                loadMoreProducts();
            }
        }, 200);

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            handleScroll.cancel();
        };
    }, [loading, loadMoreProducts]);

    const handleLike = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!');
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/products/${productId}/like`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.ok) {
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === productId
                        ? {
                              ...product,
                              likes: data.likes,
                              dislikes: data.dislikes,
                              likedByUser: !product.likedByUser,
                              dislikedByUser: false,
                          }
                        : product
                )
            );
        } else {
            console.error('Error liking product:', data);
        }
    };

    const handleDislike = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!');
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/products/${productId}/dislike`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.ok) {
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === productId
                        ? {
                              ...product,
                              dislikes: data.dislikes,
                              likes: data.likes,
                              dislikedByUser: !product.dislikedByUser,
                              likedByUser: false,
                          }
                        : product
                )
            );
        } else {
            console.error('Error disliking product:', data);
        }
    };

    return (
        <div className="store-page-wrapper">
            {errorLoading && (
                <div className="error-message">
                    <p>خطا در بارگذاری داده ها</p>
                    <button
                        className="store-page-try-agin"
                        onClick={() => {
                            setErororLoading(false);
                            loadProducts();
                        }}
                    >
                        تلاش دوباره
                    </button>
                </div>
            )}
            <div className="store-page">
                <h1 className="store_page_title_product">محصولات محبوب</h1>
                <div className="cart-icon-container">
                    <Link to="/cart">
                        <i className="fas fa-shopping-cart cart-icon"></i>
                        {getCartItemCount() > 0 && (
                            <span className="cart-item-count">{getCartItemCount()}</span>
                        )}
                    </Link>
                </div>
                <div className="store-page__product-list">
                    {products.length > 0 ? (
                        products.map((product) => (
                            <div key={product.id} className={`store-page__product-card ${product.discount > 0 ? 'discounted' : ''}`}>
                                {product.discount > 0 &&
                                    Math.round((product.discount / product.price) * 100) > 1 && ( // بررسی درصد تخفیف
                                        <span className="discount-label">
                                            تخفیف {Math.round((product.discount / product.price) * 100)}%
                                        </span>
                                    )}
    
                                <Link to={`/product/${product.id}`}>
                                    <img src={product.image} alt={product.name} className="store-page__product-image" />
                                    <p className="store-page__product-id">کد: {product.id}</p>
                                    <h2 className="store-page__product-name">{product.name}</h2>
                                </Link>
    
                                <div className="store-page__product-price">
                                    {product.discount > 0 ? (
                                        <>
                                            <p className="original-price">{formatPrice(product.price)}</p>
                                            <p className="final-price">{formatPrice(product.price - product.discount)}</p>
                                        </>
                                    ) : (
                                        <p className="final-price">{formatPrice(product.price)}</p>
                                    )}
                                </div>
    
                                <p className="store-page__product-quantity">
                                    موجودی: {product.quantity}
                                </p>
    
                                {product.quantity > 0 ? (
                                    <>
                                        <div className="quantity-controls">
                                            {cart[product.id] ? (
                                                <>
                                                    <button onClick={() => removeFromCart(product.id)}>-</button>
                                                    <span>{cart[product.id].quantity}</span>
                                                    <button onClick={() => addToCart(product)}>+</button>
                                                </>
                                            ) : (
                                                <button className="store-page__buy-button" onClick={() => addToCart(product)}>
                                                    افزودن به سبد خرید
                                                </button>
                                            )}
                                        </div>
                                        {cart[product.id] && (
                                            <div className="go-to-cart">
                                                <Link to="/cart">
                                                    <button className="store-page__go-to-cart-button">
                                                        رفتن به سبد خرید ({getCartItemCount()})
                                                    </button>
                                                </Link>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <button className="store-page__out-of-stock" disabled>
                                        ناموجود
                                    </button>
                                )}
    
                                <div className="store-page__likes-dislikes">
                                    <span
                                        onClick={() => handleLike(product.id)}
                                        style={{ color: product.likedByUser ? '#4CAF50' : '#000' }}
                                    >
                                        <i className="fas fa-thumbs-up"></i> {product.likes}
                                    </span>
                                    <span
                                        onClick={() => handleDislike(product.id)}
                                        style={{ color: product.dislikedByUser ? '#f44336' : '#000' }}
                                    >
                                        <i className="fas fa-thumbs-down"></i> {product.dislikes}
                                    </span>
                                </div>
                            </div>
                        ))
                    ) : (
                        !errorLoading && <p>در حال بارگذاری محصولات...</p>
                    )}
                </div>
                {loading && !errorLoading && <p>در حال بارگذاری...</p>}
                {!hasMore && <p>محصول دیگری وجود ندارد.</p>}
                {hasMore && !errorLoading && (
                    <div className="store-page__load-more" onClick={() => setPage((prev) => prev + 1)}>
                        <i className="fas fa-plus-circle"></i>
                    </div>
                )}
            </div>
        </div>
    );
    
};

export default Store;
