import React, { useContext, useState, useRef } from 'react';
import { CartContext } from '../context/CartContext'; // import CartContext
import { Link } from 'react-router-dom';
import './Cart.css';
import { FaTrashAlt, FaStore, FaUserAlt, FaPhoneAlt, FaMapMarkerAlt ,FaRegSmileBeam} from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const { cart, addToCart, removeFromCart, getCartItemCount, deleteItemById, setCart } = useContext(CartContext);
    const [userName, setUserName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});
    const phoneInputRef = useRef(null);
    const addressInputRef = useRef(null);
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    // فرمت کردن قیمت
    const formatPrice = (price) => new Intl.NumberFormat('fa-IR').format(price) + ' تومان';

    // محاسبه مجموع قیمت
    const getTotalPrice = () => {
        return Object.values(cart).reduce((total, product) => total + (product.productDetails.price * product.quantity), 0);
    };

    // اضافه کردن محصول به سبد خرید
    const handleAddToCart = (productId) => {
        const product = cart[productId];
        if (product) {
            addToCart(product.productDetails);
        }
    };

    // حذف یا کاهش تعداد محصول
    const handleRemoveFromCart = (productId) => {
        const product = cart[productId];
        if (product.quantity > 1) {
            removeFromCart(productId);
        } else {
            removeFromCart(productId);
        }
    };

    // پاک کردن سبد خرید
    const clearCart = () => setCart({});

    // اعتبارسنجی ورودی‌ها
    const validateInputs = () => {
        const newErrors = {};
        if (!userName.trim()) newErrors.userName = 'نام خریدار نمی‌تواند خالی باشد.';
        if (!phoneNumber.trim() || !/^\d{11}$/.test(phoneNumber)) newErrors.phoneNumber = 'شماره تلفن باید ۱۱ رقم باشد.';
        if (!address.trim()) newErrors.address = 'آدرس نمی‌تواند خالی باشد.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // درخواست پرداخت و ارسال اطلاعات
    const handleCheckout = () => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید.');
            navigate('/login'); // هدایت به صفحه ورود
            return;
        }
        if (!validateInputs()) return;

        const cartItems = Object.keys(cart).map((productId) => ({
            productId,
            quantity: cart[productId].quantity,
        }));

        const userDetails = {
            phoneNumber: phoneInputRef.current.value,
            address: addressInputRef.current.value,
        };
        const token = localStorage.getItem('access_token'); // دریافت توکن از localStorage

        fetch('/request/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // اضافه کردن توکن به هدر درخواست
            },
            body: JSON.stringify({ cart: cartItems, details: userDetails }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.paymentUrl) {
                    window.location.href = data.paymentUrl;
                } else {
                    alert('خطایی در اتصال به درگاه پرداخت رخ داده است.');
                }
            })
            .catch(() => alert('مشکلی پیش آمده است. لطفاً دوباره تلاش کنید.'));
    };

    return (
        <div className="cart-page__wrapper">
            <h1 className="cart-page__title">سبد خرید</h1>
            <div className="cart-page__content">
            {getCartItemCount() === 0 ? (
                <div className="cart-page__empty">
                    <FaRegSmileBeam size={50} color="#ffcc00" className="cart-page__empty-icon" />
                    <p className="cart-page__empty-message">
                        <strong>سبد خرید شما خالی است!</strong>
                    </p>
                    <p className="cart-page__empty-description">
                        شما هنوز هیچ محصولی به سبد خرید خود اضافه نکرده‌اید.
                    </p>
                    <Link to="/store">
                        <button className="cart-page__btn-go-to-store">
                            <FaStore size={20} style={{ marginLeft: '8px' }} />
                            رفتن به فروشگاه
                        </button>
                    </Link>
                </div>
                ) : (
                    <div className="cart-page__items-list">
                        {Object.keys(cart).map((productId) => {
                            const product = cart[productId];
                            const { name, id, price,image } = product.productDetails;

                            return (
                                <div key={productId} className="cart-page__item">
                                    <div className="cart-page__item-details">
                                        <img src={image} alt={name} className="cart-page__item-image" />
                                        <div className="cart-page__item-info">
                                            <h3 className="cart-page__product-name">{name}</h3>
                                            <p className="cart-page__product-code">کد: {id}</p>
                                            <p className="cart-page__product-price">بها: {formatPrice(price)}</p>
                                        </div>
                                    </div>
                                    <div className="cart-page__item-quantity">
                                        <button onClick={() => handleRemoveFromCart(productId)} className="cart-page__quantity-btn cart-page__decrease-btn">
                                            <AiOutlineMinus size={20} />
                                        </button>
                                        <span className="cart-page__quantity-number">{product.quantity}</span>
                                        <button onClick={() => handleAddToCart(productId)} className="cart-page__quantity-btn cart-page__increase-btn">
                                            <AiOutlinePlus size={20} />
                                        </button>
                                    </div>
                                    <div className="cart-page__item-actions">
                                        <button onClick={() => deleteItemById(productId)} className="cart-page__btn-remove-item">
                                            <FaTrashAlt size={20} color="red" />
                                        </button>
                                        <div className="cart-page__item-total-price">
                                            {formatPrice(price * product.quantity)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="cart-page__total">
                            <p>مجموع: {formatPrice(getTotalPrice())}</p>
                        </div>
                        <div className="cart-page__user-details-section">
                            <div className="cart-page__input-wrapper">
                                <FaUserAlt size={20} style={{ marginRight: '10px', color: '#555' }} />
                                <input
                                    type="text"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    placeholder="نام خریدار"
                                    className="cart-page__input-user-name"
                                />
                            </div>
                            {errors.userName && <p className="cart-page__error-message">{errors.userName}</p>}
                            <div className="cart-page__input-wrapper">
                                <FaPhoneAlt size={20} style={{ marginRight: '10px', color: '#555' }} />
                                <input
                                    ref={phoneInputRef}
                                    type="text"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="شماره تلفن"
                                    className="cart-page__input-phone-number"
                                />
                            </div>
                            {errors.phoneNumber && <p className="cart-page__error-message">{errors.phoneNumber}</p>}
                            <div className="cart-page__input-wrapper">
                                <FaMapMarkerAlt size={20} style={{ marginRight: '10px', color: '#555' }} />
                                <textarea
                                    ref={addressInputRef}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="آدرس خود را وارد کنید"
                                    className="cart-page__input-address"
                                ></textarea>
                            </div>
                            {errors.address && <p className="cart-page__error-message">{errors.address}</p>}
                        </div>
                        <div className="cart-page__checkout-section">
                            <button onClick={handleCheckout} className="cart-page__btn-checkout">
                                ادامه فرآیند خرید و پرداخت
                            </button>
                        </div>
                    </div>
                )}
                {getCartItemCount() > 0 && (
                    <div className="cart-page__footer">
                        <button onClick={clearCart} className="cart-page__btn-clear-cart">
                            پاک کردن سبد خرید
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;
