import React, { createContext, useState } from 'react';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [errorLoading, setErororLoading] = useState(false);

    const loadProducts = async () => {
        if (errorLoading ||loading || !hasMore) return;
        setLoading(true);
        try {
            const response = await fetch(`/api/products/popular?page=${page}`);
            const data = await response.json();
            if (response.ok) {
                setProducts((prev) => page === 1 ? data.products : [...prev, ...data.products]);
                setHasMore(data.has_next);
                setPage(prevPage => prevPage + 1); // بروزرسانی صفحه برای درخواست بعدی
                setErororLoading(false)
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setErororLoading(true)
        } finally {
            setLoading(false);
            
        }
    };

    return (
        <ProductContext.Provider value={{ products, loading, hasMore, errorLoading,setErororLoading,setProducts, setPage, loadProducts }}>
            {children}
        </ProductContext.Provider>
    );
};
