import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import './Comment.css'; // استایل‌های مربوط به کامنت‌ها
import './ProductDetails.css'; // استایل‌های مربوط به جزئیات محصول
import Comment from './Comment'; // وارد کردن کامپوننت Comment

const ProductDetails = () => {
    const { productId } = useParams(); // دریافت شناسه محصول از URL
    const [product, setProduct] = useState(null);
    const [comments, setComments] = useState([]);

    const formatPrice = (price) => {
        return new Intl.NumberFormat('fa-IR').format(price);
    };
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`/api/products/${productId}`);
                const data = await response.json();
                setProduct(data);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        const fetchComments = async () => {
            try {
                const response = await fetch(`/api/products/${productId}/comments`);
                const data = await response.json();
                setComments(data);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchProductDetails();
        fetchComments();
    }, [productId]);

    const handleCommentAdded = (newComment) => {
        setComments((prevComments) => [...prevComments, newComment]);
    };

    if (!product) return <p className="loading">در حال بارگذاری جزئیات محصول...</p>;

    return (
        <motion.div 
            className="product-details" 
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1 }} 
            transition={{ duration: 0.5 }}
        >
            <div className="product-intro">
                <h2>معرفی محصول</h2>
                <p>این محصول دارای ویژگی‌های منحصر به فردی است که شامل...</p>
            </div>
            <img src={product.image} alt={product.name} className="product-image" />
            <h1>{product.name}</h1>
            <p className="price"> {formatPrice(product.price)} تومان</p>
            <p className="description">{product.description}</p>
            <h2>تعداد خرید: {product.purchase_count}</h2>
            <h3><FontAwesomeIcon icon={faComment} /> کامنت‌ها:</h3>
            <ul className="comments-list">
                {comments.map((comment, index) => (
                    <li key={index} className="comment-card">
                        <div className="comment-content">
                            <span className="comment-user">{comment.user_name}</span>
                            <p className="comment-text">{comment.comment}</p>
                            <span className="comment-time">1 ساعت پیش</span>
                        </div>
                    </li>
                ))}
            </ul>
            <Comment productId={productId} onCommentAdded={handleCommentAdded} />
        </motion.div>
    );
};

export default ProductDetails;
