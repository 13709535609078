import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as authLogin } from '../services/AuthService';
import { AuthContext } from '../context/AuthContext';
import './Login.css'; // اضافه کردن استایل CSS

const Login = () => {
    const { login,isAuthenticated } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    if (isAuthenticated){
        navigate('/');

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // بررسی نام کاربری
        if (!/^[a-zA-Z0-9]+$/.test(username)) {
            setError('نام کاربری باید فقط شامل حروف انگلیسی باشد.');
            return;
        }

        try {
            const data = await authLogin(username, password);
            localStorage.setItem('access_token', data.access_token);
            login(data.user);
            
                // بررسی وضعیت تاریخچه مرورگر
            if (window.history.state && window.history.state.idx > 0) {
                    navigate(-1); // بازگشت به صفحه قبلی
                } else {
                    navigate('/'); // هدایت به صفحه اصلی
                }
           
        } catch (err) {
            setError('نام کاربری یا رمز عبور نادرست است.');
        }
    };

    return (
        
        <div className="login-container">
            <h2>ورود</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="نام کاربری"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="رمز عبور"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">ورود</button>
            </form>
            {error && <p className="error-message">{error}</p>}
            <p className="register-prompt">
                آیا حساب کاربری ندارید؟{' '}
                <span className="register-link" onClick={() => navigate('/register')}>
                    ثبت نام
                </span>
            </p>
        </div>
    );
};

export default Login;
