// RentalsContext.js
import React, { createContext, useState, useContext } from 'react';

const RentalsContext = createContext();

export const RentalsProvider = ({ children }) => {
    const [rentals, setRentals] = useState([]); // لیست املاک
    const [filters, setFilters] = useState({}); // فیلترها
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);


    return (
        <RentalsContext.Provider value={{ rentals, setRentals, filters, setFilters ,loading,setLoading,currentPage,setCurrentPage,totalPages,setTotalPages,error,setError}}>
            {children}
        </RentalsContext.Provider>
    );
};

export const useRentals = () => useContext(RentalsContext);
